import Footer from "Components/Footer"
import Header from "Components/Header"

import ionaught from 'assets/images/ionaught.jpg';


const Sponser = () => {
    return (
        <div className="dashboard-main-section">
            <Header />
            <div className="dashboard-main-container container">
                <div className="sponser-title">
                    Developed And Maintained By
                </div>
                <div className="sponser-info-container">
                    <div className="sponser-logo"><img src={ionaught} alt={ionaught} /></div>
                    <div className="sponser-info">
                        <div className="sponser-name">Ionaught Technologies Pvt. Ltd.</div>
                        <div className="sponser-sub-title"><a style={{ color: '#000' }} href="https://ionaught.com" target="_blank">www.ionaught.com</a></div>
                        <div className="sponser-description">FrontendWarfare is an online learning platform that helps front-end developers enhance their skills(HTML, CSS, and JS). We made it free so anyone can access it. We are an emerging web development company and, we know the importance of developing skills in HTML, CSS, and JS. We believe FrontendWarfare is a perfect first step to becoming a web developer.</div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Sponser
