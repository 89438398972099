//import packages
import { useNavigate } from "react-router-dom";

//importing components
import Header from "Components/Header"

//importing assets
import pageNotFound from 'assets/images/page-not-found.png';
import home from 'assets/icons/home.svg';
import Footer from "Components/Footer";

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <div className="dashboard-main-section">
            <Header />
            <div className="dashboard-main-container container">
                <div className="page-not-found-container">
                    <div className="page-not-found-image-container">
                        <img src={pageNotFound} alt="Page not found" className="page-not-found-image" />
                    </div>
                    <div className="page-not-found-content">
                        <div className="page-not-found-title">Oops!</div>
                        <div className="page-not-found-sub">Page Not Found</div>
                        <div className="back-to-home-btn" onClick={() => { navigate('/') }}>Back To Home <img src={home} alt="home" className="back-to-home-img" /></div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PageNotFound
