import "./style.css"
import Layout from './Components/Layout';
import Route from './Components/Pages';
import { useState } from "react";
import Register from "./Components/Pages/Register";
import Login from "./Components/Pages/Login";
import RegisterSuccess from "./Components/Pages/RegisterSuccess";
import DashBoard from "./Components/Pages/DashBoard";

const MarkIt = () => {
  
  const pageNames = {
    register:0,
    login:1,
    registerSuccess:2,
    dashBoard:3
  }

  const pages = [
    {
      endPoint:0,
      Component: Register
    },
    {
        endPoint:1,
        Component: Login
    },
    {
        endPoint: 2,
        Component: RegisterSuccess
    },
    {
        endPoint: 3,
        Component: DashBoard
    }
  ]

  const [activePage,setActivePage] = useState(pageNames.register);

  const changePage = async(newPage)=>{
    setActivePage(newPage);
  }

  return (
    <Layout>
      {pages.map(({endPoint,Component})=>
        <Route
          key={endPoint}
          active={activePage === endPoint}
          >
          <Component
            changePage={changePage}
            pageNames={pageNames}
            active={activePage === endPoint}
            />
        </Route>
      )}
    </Layout>
  )
}

export default MarkIt