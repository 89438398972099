const Route = ({ children, position,active }) => {
  return (
    <div
      className={`page-container ${active? "page-container-active":""}`}
     
    >
      {children}
    </div>
  );
};

export default Route;
