
//importing components
import ProfileView from "./ProfileView"
import { UserContext } from "Contexts/UserContext";

//importing packages
import { useNavigate } from "react-router-dom";
import { useContext, useRef, useEffect } from "react";


//import assets
import user from 'assets/icons/user.svg';
import logOut from 'assets/icons/log-out.svg';


const ProfilePopup = () => {
    const { userData, setUserData } = useContext(UserContext);
    const navigate = useNavigate();

    const profilePopUpRef = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (profilePopUpRef.current && !profilePopUpRef.current.contains(e.target)) {
            setTimeout(() => {
                setUserData({ ...userData, profilePopupVisibility: false });
            }, 200);
        }
    };

    const signout = () => {
        setUserData({
            signinVisibility: false,
            isLoggedin: false,
            userImg: '',
            userFullName: '',
        })
        localStorage.removeItem('token')
        window.location.reload(false)
    }


    return (
        <div ref={profilePopUpRef} className="profile-popup-container">
            <ProfileView name={userData.userFullName} createdAt={userData.memberSince} score={userData.score} level={userData.level} rank={userData.rank} medium />
            <div className="profile-button-container">
                <div className="profile-popup-button" onClick={() => { navigate(`/${userData.userId}`); setUserData(prev => { return { ...prev, profilePopupVisibility: false } }) }}><img src={user} alt="user" className="profile-popup-icon" />Profile</div>
                <div className="profile-popup-button" onClick={signout}><img src={logOut} alt="logout" className="profile-popup-icon" />Logout</div>
            </div>
        </div>
    )
}

export default ProfilePopup
