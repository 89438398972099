import { useNavigate, useParams } from 'react-router-dom';
import Timer from './Components/Timer';
import questions, {answerTypes, contentType} from '../Data/questions';
import { getOptionLetter } from '../Utils/Common';
import SelectBox from './Components/SelectBox';
import Button from '../Components/Button';
import { Fragment, useEffect, useRef, useState } from 'react';
import InputField from './Components/InputField';
import TextArea from './Components/TextArea';
import InputBox from './Components/InputBox';
import postCall from '../Services/postCall';

const Question = ({answers,setAnswersList,isExamStarted}) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [answer, setAnswer] = useState(answers[id]);
    const [timer, setTimer] = useState(90*60);
    const [isExamStart, setIsExamStart] = useState(false);
    const videoRef = useRef(null);
    const interval = useRef(null);

    useEffect(() => {
        if(!isExamStart) return;
        interval.current = setInterval(() => {
            if(timer > 0) return setTimer(prev=>prev - 1);
            navigate('/iq-test/finish');
            clearInterval(interval.current);
        } , 1000);
        return () => clearInterval(interval.current);
    } , [isExamStart, timer]);

    useEffect(() => {
        getVideo();
    } , []);

    useEffect(() => {
        postCall('/user/update-exam', {
            challenge: answers,
        })
            .then(res => {
                if (!res.status) {
                    return alert(res.message);
                }
            }
            );
    } , [answers]);



    useEffect(() => {
        if(!isExamStarted) navigate('/iq-test/instructions');
    } , [isExamStarted]);

    const getVideo = () => {
        navigator.mediaDevices
          .getUserMedia({ video: { width: 300 } })
          .then(stream => {
            setIsExamStart(true);
            videoRef.current = stream;
          })
          .catch(err => {
            console.error("error:", err);
          });
      };

    useEffect(() => {
        setAnswer(answers[id]);
    } , [id]);


    const onNext = () =>{
        setAnswersList(answersList => {
            const newAnswersList = [...answersList];
            newAnswersList[id] = {...answer};
            return [...newAnswersList];
            });
        if(Number(id) > questions.length - 2)
            return setTimeout(()=>{
                navigate('/iq-test/finish');
            },1000)
        navigate(`/iq-test/question/${parseInt(id) + 1}`);
    }

    const onForward = () =>{
        if(Number(id) > questions.length - 2)return;
        navigate(`/iq-test/question/${parseInt(id) + 1}`);
    }

    const onBack = () =>{
        if(Number(id) < 1)return;
        navigate(`/iq-test/question/${parseInt(id) - 1}`);
    }

    const listDataChange = (key) => (value,index,i) => {
        setAnswer(prev=>{
            const newAnswerList = {...prev};
            const newSelectList = [...newAnswerList[key]];
            const newAnswer = [...newSelectList[index]];
            newAnswer[i] = value;
            newSelectList[index] = newAnswer;
            newAnswerList[key] = newSelectList;
            return newAnswerList;
        });
    }


    const setSelectList = (value,index,i) => {
        listDataChange("selectList")(value,index,i);
    }

    const setTextList = (value,index,i) => {
        listDataChange("textList")(value,index,i);
    }

    const setPuzzleList = (value,index,i) => {
        listDataChange("puzzleList")(value,index,i);
    }

    const setFillList = (value,index,i)=>{
        listDataChange("fillList")(value,index,i)
    }

  if(!isExamStart) return <div className='cam-warning'>Camera Should be on</div>;
  return (
    <div className='iq-test-question-container'>
        <video ref={videoRef} style={{
            position: 'absolute',
            opacity: 0,
            pointerEvents: 'none',
        }}/>
        <div className='iq-test-question-header'>
            <div className="iq-test-question-number">
                {Number(id)+1} / 30
            </div>
            <Timer remaining={timer} max={90*60}/>
        </div>
        <div className='iq-test-question-body'>
            {questions[id].question.map(({type,data}, index) => 
                <div className='question-container' key={index}>
                    {index === 0 && <div className='iq-test-question-number-circle'>{Number(id)+1}</div>}
                    {type === contentType.text && <div className='iq-test-question-text'>{data}</div>}
                    {type === contentType.image && <img src={data} alt="Question" className='iq-test-question-image' />}
                </div>
            )}
            <div className='iq-test-question-answers'>
                {
                    questions[id].answers?.map(({
                        answerType,
                        type,
                        data,
                        list,
                        numberOfSelection,
                        numberOfTextBox,
                    }, index) =>
                        <Fragment key={index}>
                        {answerType === answerTypes.option?
                         <div className='answer-container'>
                            <div className='iq-test-question-option'>
                                <input
                                    type="radio"
                                    id=""
                                    checked={index === answer?.option}
                                    onChange={() => setAnswer(prev=>({...prev,option:index}))}
                                />    
                            </div>
                            <div className='iq-test-option-letter'>
                                {getOptionLetter(index)}.
                            </div>
                            {type === contentType.text && <div className='iq-test-answer-text'>{data}</div>}
                            {type === contentType.image && <img src={data} alt="Answer" className='iq-test-answer-image' />}
                        </div>
                        :
                        answerType === answerTypes.listSelection 
                        ?
                        <div className='iq-test-select-container'>
                            {type === contentType.text && <div className='iq-test-answer-text'>{data}</div>}
                            {type === contentType.image && <img src={data} alt="Answer" className='iq-test-answer-image' />}
                            {[...Array(numberOfSelection)].map(
                                (_v,i)=>
                                <SelectBox
                                    key={i}
                                    list={list}
                                    value={answer?.selectList?.[index]?.[i] || ""}
                                    onChange={(value) => setSelectList(value,index,i)}
                                />
                            )}
                        </div>
                        :
                        answerType === answerTypes.textBox ?
                        <div className='iq-test-text-box-container'>
                            {[...Array(numberOfTextBox)].map((_v,i)=>
                                <InputField
                                    key={i}
                                    value={answer?.textList?.[index]?.[i] || ""}
                                    onChange={(value) => setTextList(value,index,i)}
                                />
                            )}
                        </div>
                        :
                        answerType === answerTypes.textArea ?
                        <TextArea
                            value={answer?.textList?.[index]?.[0] || ""}
                            onChange={(value) => setTextList(value,index,0)}
                        />
                        :answerType === answerTypes.puzzleTable ?
                            <div className='iq-test-puzzle-table-container'>
                                {data.map((row, rowIndex) =>
                                    <div className='iq-test-puzzle-table-row' key={rowIndex}>
                                        {row.map((cell, cellIndex) =>
                                            <div
                                                className='iq-test-puzzle-table-cell'
                                                key={cellIndex}
                                                style={{
                                                    backgroundColor: cell === null? '#000':null,
                                                }}
                                            >

                                                {cell === null? null:
                                                cell?cell:
                                                <InputBox
                                                    value={answer?.puzzleList?.[rowIndex]?.[cellIndex] || ""}
                                                    onChange={(value) => setPuzzleList(value,rowIndex,cellIndex)}
                                                />
                                                }
                                                
                                            </div>
                                        )}
                                    </div>
                                        )}
                            </div>
                        :answerType === answerTypes.fillInBlank ?
                        <div className='iq-test-fill-in-blank-container'>
                            {data.map((text, rowIndex) =>
                                <Fragment key={rowIndex}>
                                    {text?text:
                                    <input
                                        type="text"
                                        className='iq-test-fill-in-blank-input'
                                        value={answer?.fillList?.[index]?.[rowIndex] || ""}
                                        onChange={e=>setFillList(e.target.value,index,rowIndex)}
                                    />}
                                    {" "}
                                </Fragment>
                            )}
                        </div>
                        :null
                        }
                    </Fragment>
                    )
                }
            </div>
        </div>
        <div className='iq-test-question-footer'>
            <div
                className='iq-test-question-footer-skip'
                onClick={() => onForward()}
            >
                Skip
            </div>
            <Button
                onClick={onNext}
                label={id==="29"?"Finish":"Next"}
            />
        </div>
    </div>
  )
}


export default Question