import { useEffect } from "react"

const Finish = ({setIsExamStarted={setIsExamStarted}}) => {
    useEffect(()=>{
        setIsExamStarted(false);
    },[])
  return (
    <div className="iq-test-finish-screen"></div>
  )
}

export default Finish