//importing packages

//importing assets
import newRepo from 'assets/images/new-repo-ss.jpeg';
import repoCreation from 'assets/images/repo-creation-ss.jpg';
import submitForm from 'assets/images/submit-form-ss.jpg';

//imporing components


const Procedure = () => {
    return (
        <div className="procedure-container">
            <div className="step-container">
                <div className="procedure-title">
                    Procedure:-
                </div>
                <div className="step-info">
                    Before attending the challenge, understand the process. You must read and understand this procedure before attending the challenge. In this design to HTML/CSS and JS challenge, you can take the Figma design, and re-create it fully responsive.                </div>
            </div>
            <div className="step-container">
                <div className="step-title">
                    Step 1:- Download and Unzip
                </div>
                <div className="step-info">
                    Download and unzip the template. This template gives you a starting code and all the assets you need to get started. You can get started developing right away.                </div>
            </div>
            <div className="step-container">
                <div className="step-title">
                    Step 2:- Create Remote Repository
                </div>
                <div className="step-info">
                    Create a public GitHub repository with the challenge title. You can follow these steps. Click 'New' button under the repository section on your profile.                </div>
                <div className="procedure-image-container" style={{ height: '150px', maxWidth: '400px' }}>
                    <img src={newRepo} alt="new repository" className="procedure-image" />
                </div>
                <div className='step-info'>
                    Click the 'New' button under the repository section on your profile.
                </div>
                <div className="procedure-image-container">
                    <img src={repoCreation} alt="create a new repository" className="procedure-image" />
                </div>
                <div className="step-info">
                    Give the challenge title as the repository name, Select public as the repository type, and click Create repository Button.                </div>
            </div>
            <div className="step-container">
                <div className="step-title">
                    Step 3:- Add Remote Repository
                </div>
                <div className="step-info">
                    Open any command-line tool you like on the UnZiped folder and add the remote repository that you just have created.                </div>
                <div className="code-block">
                    {"git remote add origin https://github.com/<username>/<repo-name>.git"}
                </div>
            </div>
            <div className="step-container">
                <div className="step-title">
                    Step 4:- Develop
                </div>
                <div className="step-info">
                    Now develop the design using any code editor.
                </div>
            </div>
            <div className="step-container">
                <div className="step-title">
                    Step 5:- Commit and push the code
                </div>
                <div className="step-info">
                    Commit and push your code when you have done working on the Challenge.
                </div>
            </div>
            <div className="step-container">
                <div className="step-title">
                    Step 6:- Submit your entry
                </div>
                <div className="step-info">
                    Submit your entry on our website along with your GitHub repo URI.
                </div>
                <div className="procedure-image-container">
                    <img src={submitForm} alt="submit challenge" className="procedure-image" />
                </div>
            </div>
        </div>
    )
}

export default Procedure
