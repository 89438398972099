import React from 'react'

const Button = ({label,onClick,disabled}) => {
  return (
    <button
            className={`test-mate-primary-button ${disabled?"test-mate-primary-button-disabled":""}`}
            onClick={!disabled && onClick}
            
    >
        {label}
    </button>
  )
}

export default Button