

const ToggleButton = ({ value, onChange }) => {
    return (
        <div
            className={`js-button-container ${!value ? 'js-button-container--active' : ''}`}
            onClick={() => {
                onChange(!value);
            }}
        >
            <div className="js-button-toggler"></div>
        </div>
    )
}

export default ToggleButton
