import React from 'react'

const Input = ({label,type="text"}) => {
  return (
    <div className="mark-it-input-field">
        <input type={type} />
        <label>{label}</label>
    </div>
  )
}

export default Input