//import packages
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useEffect, useContext, useState } from "react";

// import assets

//import components
import Header from "Components/Header";
import ChallengeCard from "Components/ChallengeCard";
import ChallengeWithButton from "Components/ChallengeWithButton";
import ProfileView from "Components/ProfileView";
import getData from "Services/getData";
import { UserContext } from "Contexts/UserContext";
import { ChallengeContext } from "Contexts/ChallengeContext";
import ErrorScreen from "Components/ErrorScreen";
import SubmitChallenge from "Components/SubmitChallenge";
import Footer from "Components/Footer";



const Profile = () => {
    const navigate = useNavigate();
    const [buttonVisibility, setButtonVisibility] = useState(true)
    const { userData } = useContext(UserContext);
    const { challengeSubData, setChallengeSubData } = useContext(ChallengeContext);
    const userId = useParams().userId;
    const { data, isLoading, isError, refetch } = useQuery("profile", () => (userId === userData.userId ? getData('/user/get-user') : getData(`/user/get-profile/${userId}`)), { cacheTime: 0 });


    useEffect(() => {
        refetch();
        if (userId !== userData.userId) {
            setButtonVisibility(false)
        }
        else {
            setButtonVisibility(true)
        }
    }, [userId]);

    if (isLoading) return <div>Loading...</div>;
    if (!data) return <ErrorScreen message="Network Error" />;
    if (isError) return <ErrorScreen message="Network Error" />;
    if (!data.status) return <ErrorScreen message={data.error} />;

    return (
        <div className="dashboard-main-section">
            <Header />
            <div className="dashboard-main-container container">
                <ProfileView {...data.data} />
                <div className="profile-list-section">
                    {data.data.ongoingChallenges && data.data.ongoingChallenges.length !== 0 && <div className="profile-list-title">Pending List</div>}
                    <div className="profile-list-container">
                        {data.data.ongoingChallenges?.map((data, i) => { return <ChallengeWithButton key={i} {...data} onClick={() => { navigate(`/challenges/${data.id}`) }} buttonVisibility={buttonVisibility} /> })}
                    </div>
                </div>
                <div className="profile-list-section">
                    {data.data.submittedChallenges.length !== 0 && <div className="profile-list-title">Submissions</div>}
                    <div className="profile-list-container">
                        {data.data.submittedChallenges.map((data, i) => { return <ChallengeCard key={i} onClick={() => { navigate(`/challenges/${data.id}`) }} {...data} /> })}
                    </div>
                </div>
            </div>
            {challengeSubData.submitChallengeVisibility && <SubmitChallenge />}
            <Footer />
        </div>
    )
}

export default Profile
