//import packages
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

//importing assests
import beginner from 'assets/icons/beginner.svg'
import intermediate from 'assets/icons/intermediate.svg'
import advanced from 'assets/icons/advanced.svg'
import downloadIcon from 'assets/icons/download-icon.svg';

//import components
import ImageToggle from './ImageToggle';
import postData from 'Services/postData';

import { ChallengeContext } from 'Contexts/ChallengeContext';



const ChallengeWithButton = ({ title,
    id,
    difficulty = "Advanced",
    isJs = true,
    images = [],
    postedAt = "24/11/2021",
    figmaLink,
    onClick,
    buttonVisibility
}) => {
    const [activeTab, setActiveTab] = useState(true);
    const { challengeSubData, setChallengeSubData } = useContext(ChallengeContext);
    const navigate = useNavigate();
    const toggleImage = () => {
        setActiveTab(prev => !prev);
    }



    const downloadHandler = async () => {
        const response = await postData('/challenge/download', { id });
        const newDownloadLink = process.env.REACT_APP_API_URL + response.data;
        window.open(newDownloadLink);
        navigate('/profile');
    }

    return (
        <div className="challenge-card-container" onClick={onClick}>
            <div className="challenge-card-image-container">
                {activeTab ? <img src={process.env.REACT_APP_API_URL + images[0]} alt={title} className="challenge-card-image" /> : <img src={process.env.REACT_APP_API_URL + images[1]} alt={title} className="challenge-card-image" />}
                <ImageToggle activeTab={activeTab} onClick={(e) => { e.stopPropagation(); toggleImage() }} />
            </div>
            <div className="challenge-card-info">
                <div className="challenge-card-title-container">
                    <div className="challenge-card-title">{title}</div>
                </div>
                <div className="challenge-card-sub-container">
                    <div className="challenge-card-type"><img
                        src={difficulty === "Beginner" ? beginner : difficulty === "Intermediate" ? intermediate : advanced}
                        alt="type symbol"
                        className="type-symbol" />{difficulty}</div>
                    {isJs ? <div className="challenge-card-type challenge-card-js">JS</div> : <div></div>}
                    <div className="challenge-card-date">{postedAt}</div>
                </div>
                <div className="challenge-button-container">
                    {buttonVisibility && <button className="common-button submit-button" onClick={(e) => { e.stopPropagation(); setChallengeSubData(prev => { return { ...prev, title: id, submitChallengeVisibility: true } }) }}>Submit</button>}
                    <a className="common-button figma-button" href={figmaLink} target="_blank">View Design</a>
                    <button className="common-button download-button" onClick={(e) => { e.stopPropagation(); downloadHandler() }}>Download <img src={downloadIcon} alt="download" className="download-icon" /></button>
                </div>
            </div>
        </div>
    )
}

export default ChallengeWithButton
