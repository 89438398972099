import Button from "../Inputs/Button"
import Input from "../Inputs/Input"

const Popup = ({onClose}) => {
  return (
    <div className="mark-it-popup">
        <div className="mark-it-popup-container">
            <header>
                <h3>Add Task</h3>
                <div onClick={onClose}>Close</div>
            </header>
            <main>
                <form>
                    <Input label="Project"/>
                    <Input label="Title"/>
                    <Input label="Description"/>
                    <Input label="Assign"/>
                    <Button label="Register"/>
                </form>
            </main>
        </div>
    </div>
  )
}

export default Popup