//importing packages
import { useContext, useRef, useEffect } from 'react'

//importing components
import { UserContext } from 'Contexts/UserContext'

//importing assets
import githubIcon from 'assets/icons/github-icon.svg'



const Signin = () => {
    const { userData, setUserData } = useContext(UserContext)
    const signinPopupRef = useRef(null)

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleClickOutside = (e) => {
        if (signinPopupRef.current && !signinPopupRef.current.contains(e.target)) {
            setTimeout(() => {
                setUserData({ ...userData, signinVisibility: false });
            }, 200);
        }
    }

    return (
        <div className="popup-container" >
            <div className="signin-button-container" ref={signinPopupRef}>
                <button className="github-button">
                    <img src={githubIcon} alt="github" className="github-icon" />
                    <a href={`https://github.com/login/oauth/authorize?client_id=867668995fe8eeb2bcb0&redirect_uri=${userData.isTryNow ? window.location.href + "?trynow=true" : window.location.href}&scope=read:user`}>Sign In With GitHub</a>
                </button>
            </div>
        </div >
    )
}

export default Signin
