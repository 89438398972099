// import assets
import linkedin from 'assets/icons/Linkedin.svg';
import instagram from 'assets/icons/Instagram.svg';
import facebook from 'assets/icons/Facebook.svg';
import twitter from 'assets/icons/Twitter.svg';
import ionaught from 'assets/icons/ionaught.svg';

import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();
    return (
        <div className="footer-container">
            <div className="footer-main-section">
                <div className="sponsers" onClick={() => { navigate('/sponsers') }}>Sponsers <div className='sponsers-logos'><img src={ionaught} alt="ionaught logo" className='sponser-logo-small' /></div></div>
                <div className="social-media-container">
                    <a href="https://www.linkedin.com/company/ionaught-technologies-pvt-ltd/" target="_blank"><img src={linkedin} alt="linkedin" className='social-icons' /></a>
                    <a href="https://twitter.com/ionaughttech" target="_blank"><img src={twitter} alt="twitter" className='social-icons' /></a>
                    <a href="https://www.facebook.com/ionaught.tech/" target="_blank"><img src={facebook} alt="facebook" className='social-icons' /></a>
                    <a href="https://www.instagram.com/ionaught/" target="_blank"><img src={instagram} alt="facebook" className='social-icons' /></a>
                </div>
                <div className="copyright-container">
                    All Rights Reserved 2021  |  © Ionaught Technologies Pvt. Ltd.
                </div>
            </div>
        </div>
    )
}

export default Footer
