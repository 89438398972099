//import packages
import { useState } from 'react';

//importing assests
import beginner from 'assets/icons/beginner.svg'
import intermediate from 'assets/icons/intermediate.svg'
import advanced from 'assets/icons/advanced.svg'

//import components
import ImageToggle from './ImageToggle';



const ChallengeCard = ({ title = "Design Title",
    onClick = { onClick },
    difficulty = "Advanced",
    isJs = true,
    status = null,
    score = 0,
    postedAt = "24/11/2021",
    images = [],
    description = "this is a very nice line sample design for testing purpose this is again a new line of code thats completely nonsence adding some more line to fit there to try line clamp" }) => {
    const [activeTab, setActiveTab] = useState(true);
    const toggleImage = () => {
        setActiveTab(prev => !prev);
    }
    return (
        <div className="challenge-card-container" onClick={onClick}>
            <div className="challenge-card-image-container">
                {activeTab ? <img src={process.env.REACT_APP_API_URL + images[0]} alt={title} className="challenge-card-image" /> : <img src={process.env.REACT_APP_API_URL + images[1]} alt={title} className="challenge-card-image" />}
                <ImageToggle activeTab={activeTab} onClick={(e) => { e.stopPropagation(); toggleImage() }} />
            </div>
            <div className="challenge-card-info" >
                <div className="challenge-card-title-container">
                    <div className="challenge-card-title">{title}</div>
                    {status === 'submit' && <div className="challenge-card-pending">Pending...</div>}
                </div>

                <div className="challenge-card-sub-container">
                    <div className="challenge-card-type"><img
                        src={difficulty === "Beginner" ? beginner : difficulty === "Intermediate" ? intermediate : advanced}
                        alt="type symbol"
                        className="type-symbol" />{difficulty}</div>
                    {isJs ? <div className="challenge-card-type challenge-card-js">JS</div> : <div></div>}
                    <div className="challenge-card-date">{postedAt}</div>
                </div>
                <div className="challenge-card-desc">
                    {description}
                </div>
            </div>
            {status === 'complete' && <div className="challenge-card-submission">{score}/10</div>}
        </div>
    )
}

export default ChallengeCard
