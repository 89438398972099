import { useState } from "react";
import Popup from "../../Popup"

const DashBoard = () => {

    const [showPopup, setShowPopup] = useState(false);

    const tabLink = [
        {
            label: "Dashboard",
            active: true,
        },
        {
            label: "Tasks",
        },
        {
            label: "Logout",
        },
    ]

  return (
    <>
    <div className="mark-it-dashboard-container">
        <header>
            <h2>MarkIt</h2>
        </header>
        <div className="mark-it-dashboard-content-section">
            <div className="mark-it-dashboard-content-header">
                {tabLink.map(({label,active},index)=>
                    <div
                        className={
                            `mark-it-dashboard-tabs ${active? "mark-it-dashboard-tabs-active":""}`
                        }
                        key={index}
                    >
                        {label}
                    </div>
                )}
            </div>
            <div className="mark-it-dashboard-content-body">
                <div
                    className="mark-it-dashboard-add-task-button"
                    onClick={()=>setShowPopup(true)}
                >
                    Add Task
                </div>
                <div className="mark-it-dashboard-feeds">
                </div>
            </div>
        </div>
    </div>
    {showPopup && <Popup onClose={()=>setShowPopup(false)}/>}
    </>
  )
}

export default DashBoard