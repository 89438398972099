//import packages
import { useQuery } from "react-query"

//import components
import Header from "Components/Header"
import LeaderBoardComponent from "Components/LeaderBoardComponent"
import getData from "Services/getData"
import ErrorScreen from "Components/ErrorScreen"
import Footer from "Components/Footer"

const Leaderboard = () => {
    const { data, isLoading, isError } = useQuery("leaderboard", () => getData('/user/leaderboard'))

    if (isLoading) return <div>Loading...</div>
    if (!data) return <ErrorScreen message="Network Error" />;
    if (isError) return <ErrorScreen message="Network Error" />;



    return (
        <div className="dashboard-main-section">
            <Header />
            <div className="dashboard-main-container container">
                <LeaderBoardComponent leaderboard={data.data} />
            </div>
            <Footer />
        </div>
    )
}

export default Leaderboard
