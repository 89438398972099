import postCall from 'Pages/TestMate/Services/postCall';
import Button from '../Inputs/Button'

const Instruction = ({changePage,pageNames}) => {

    const startTest = async()=>{
        const result = await postCall("/user/start-test");
        if(!result.status) return alert("Failed to start");
        changePage(pageNames.question);
    }
  return (
    <div className='instruction-page-container'>
        <h1 className='page-title'>Instructions</h1>
        <ul className="instructions-list-container">
            <li>
                There will be exactly 25 questions and each question carry the same weight.
            </li>
            <li>
            You will be shown one question at a time and cannot go back to a question once you answered or skipped it.
            </li>
            <li>
            The test will be auto-submitted once the total of 30 minutes is over.
            </li>
            <li>
            Don't press refresh or back button when you are in the test screen.
            </li>
        </ul>
        <Button
            label={"START EXAM"}
            onClick={startTest}
        />
    </div>
  )
}

export default Instruction