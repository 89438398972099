import Link from "../Inputs/Link"

const RegisterSuccess = ({changePage,pageNames}) => {
  return (
    <div className="mark-it-container-home-box">
        <h1>
        Your have Registered Successfully!
        </h1>
        <Link
            label="Go to Login"
            onClick={()=>changePage(pageNames.login)}
        />
    </div>
  )
}

export default RegisterSuccess