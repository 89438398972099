import { useNavigate, useSearchParams } from 'react-router-dom';
import Layout from './Layout'
import homeIllustration from "../Assets/home-illustration.svg";
import { useEffect, useState } from 'react';
import postCall from '../Services/postCall';

const Home = () => {
    const [searchParams, _setSearchParams] = useSearchParams();
    const [name, setName] = useState('');
    const navigate = useNavigate();
    useEffect(()=>{
        if(!searchParams.get('id'))return navigate('/notfound');
        localStorage.setItem('iq-test-token', searchParams.get('id'));
        postCall('/user/get-test-data', {}).then(res=>{
            if(!res.status){
               return navigate('/notfound');
            }
            setName(res.name);
        });
    },[]);


  return (
    <Layout
        title="Online Aptitude Test-1 (IQ Test)"
        onStart={() => navigate("/iq-test/instructions")}
        illustration= {homeIllustration}
    >
        <p className="iq-home-text">
            Hello {name},
        </p>
        <p className="iq-home-text">
        Welcome to the Ionaught Technologies selection process, This is the first online aptitude test.
        </p>
    </Layout>
  )
}

export default Home