import { getTimeString } from 'Pages/IqTest/Utils/Common';
import React from 'react'

const Timer = ({remaining= 50,max=100}) => {

    const radius = 30;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (remaining /max) * circumference;
  return (
    <svg
        className="progress-ring"
        width="70"
        height="70">
            <circle
            className="progress-ring__circle"
            stroke="#eaeaea"
            strokeWidth="5"
            fill="transparent"
            r={radius}
            cx="35"
            cy="35"/>
        <circle
            className="progress-ring__circle"
            style={{
                strokeDasharray: `${circumference} ${circumference}`,
                strokeDashoffset,
            }}
            stroke="#085994"
            strokeWidth="5"
            fill="transparent"
            r={radius}
            cx="35"
            cy="35"/>
            <text
                x="50%" y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize={16}    
            >
                {getTimeString(remaining)}
            </text>
    </svg>
  )
}

export default Timer