import React from 'react'

const CheckBox = ({label,option,id,value,onClick}) => {
    console.log(option);
    const getOption = (no)=>{
        switch(no){
            case 1:
                return "A";
            case 2:
                return "B";
            case 3:
                return "C";
            case 4:
                return "D";
            default:
                return null;
        }
    }
  return (
    <div className='test-app-check-box-container'>
        <input
            type="radio"
            name={id}
            checked={value === label}
            onClick={onClick}
        />
        <span style={{
            marginLeft: "15px",
            marginRight: "10px",
            fontWeight: "normal"
        }}>
            {getOption(option)}.
        </span>
        {label}
    </div>
  )
}

export default CheckBox