import legend from 'assets/icons/legend.svg';
import eliteMaster from 'assets/icons/elite-master.svg';
import master from 'assets/icons/master.svg';
import eliteChampion from 'assets/icons/elite-champion.svg';
import champion from 'assets/icons/champion.svg';
import veteran from 'assets/icons/veteran.svg';
import knight from 'assets/icons/knight.svg';
import apprentice from 'assets/icons/apprentice.svg';
import recruit from 'assets/icons/recruit.svg';

const levelEmblem = (level) => {
    switch (level) {
        case 'Legend':
            return legend;
        case 'Elite Master':
            return eliteMaster;
        case 'Master':
            return master;
        case 'Elite Champion':
            return eliteChampion;
        case 'Champion':
            return champion;
        case 'Veteran':
            return veteran;
        case 'Knight':
            return knight;
        case 'Apprentice':
            return apprentice;
        case 'Recruit':
            return recruit;
        default:
            return null;
    }
};

export default levelEmblem;