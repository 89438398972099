export const getOptionLetter = (index) => {
  const letters = ["A", "B", "C", "D", "E"];
  return letters[index];
};

export const getTimeString = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return `${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;
};
