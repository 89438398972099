//import packages
import levelEmblem from 'Helper/levelEmblem';
//importing assets
import avatarImage from 'assets/images/sample-avatar.jpg'

const Avatar = ({ image, small, medium, onClick, level }) => {
    return (
        <div className={small ? 'avatar-container' : medium ? 'avatar-medium' : 'avatar-big'} onClick={onClick}>
            <img src={image ? image : avatarImage} alt="avatar" className="avatar-image" />
            {!small && <img src={levelEmblem(level)} alt="level" className={medium ? "avatar-level-medium" : "avatar-level"} />}
        </div>
    )
}

export default Avatar
