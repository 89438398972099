const Link = ({onClick,label}) => {
  return (
    <div
        className="mark-it-link"
        onClick={onClick}
    >
        {label}
    </div>
  )
}

export default Link