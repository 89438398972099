const InputField = ({onChange,value}) => {
  return (
    <div className='iq-test-text-box'>
        <input
            type="text"
            onChange={
                (e) => onChange(e.target.value)
                }
            value={value}
        />
    </div>
  )
}

export default InputField