//importing packages
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
//importing components and style
import Header from 'Components/Header';
import SearchSortFilter from 'Components/SearchSortFilter';
import ChallengeCard from 'Components/ChallengeCard';
import getData from 'Services/getData';
import ErrorScreen from 'Components/ErrorScreen';
import Footer from 'Components/Footer';

const Dashboard = () => {
    const [page, setPage] = useState(1);
    const [nextpage, setNextpage] = useState(false);
    const [searchSortData, setSearchSortData] = useState({
        search: '',
        sort: 'Recent',
        activeDifficulty: 'all',
        isNoJs: false,
    });
    const { search, sort, activeDifficulty, isNoJs } = searchSortData;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);


    useEffect(() => {
        setTimeout(() => {
            refetch();
            setPage(1);
        }, 100);
    }, [searchSortData]);

    let navigate = useNavigate();

    const { data, error, isLoading, isError, isFetching, refetch } = useQuery(['challenges', page], () => getData(`/challenge/challengelist?${(page ? 'page=' + page + '&' : '') + (search ? 'search=' + search + '&' : '') + (sort ? 'sort=' + sort + '&' : '') + (activeDifficulty ? 'activeDifficulty=' + activeDifficulty + '&' : '') + (isNoJs ? 'isNoJs=' + isNoJs + '&' : '')}`), { keepPreviousData: true });
    if (isLoading) return <div>Loading...</div>;
    if (!data) return <ErrorScreen message="Network Error" />;
    if (isError) return <ErrorScreen message="Network Error" />;

    //const { challenges, pages, isLast } = data;


    // window.addEventListener('scroll', () => {
    //     if (window.innerHeight + window.scrollY >= document.body.clientHeight - 400 && page < data.pages) {
    //         setNextpage(true);
    //         return
    //     }
    // });

    return (
        <div className="dashboard-main-section">
            <Header />
            <div className="dashboard-main-container container">
                <SearchSortFilter searchSortData={searchSortData} setSearchSortData={setSearchSortData} />
                {!isLoading && !error && data?.data?.length === 0 && <div style={{ textAlign: 'center', fontWeight: 'bold', letterSpacing: '2px', marginTop: '30px' }}>No Challenges Found</div>}
                <div className="challenge-list-container">
                    {!isLoading && !error && data?.data?.map((data, i) => { return <ChallengeCard key={i} {...data} onClick={() => { navigate(`/challenges/${data.id}`) }} /> })}
                </div>
                {/* <div className="pagination-container">
                    {!isLoading && !error && data.data.length > 0 && <div className={page === 1 ? "page-button-disabled" : "page-button"} onClick={() => { page > 1 && setPage(page - 1) }}>{'<<'} Previous Page</div>}
                    {!isLoading && !error && data.data.length > 0 && <Paginater totalPages={data.pages} currentPage={page} setPage={setPage} />}
                    {!isLoading && !error && data.data.length > 0 && <div className={data.isLast ? "page-button-disabled" : "page-button"} onClick={() => { !data.isLast && setPage(page + 1) }}>Next Page {'>>'}</div>}
                </div> */}
                <div className="page-button-container">
                    {!isLoading && !error && data?.data?.length > 0 && <div className={page === 1 ? "page-button-disabled" : "page-button"} onClick={() => { page > 1 && setPage(page - 1) }}>{'<<'} Previous Page</div>}
                    {isFetching && <span> Loading...</span>}{' '}
                    {!isLoading && !error && data?.data?.length > 0 && <div className={data.isLast ? "page-button-disabled" : "page-button"} onClick={() => { !data.isLast && setPage(page + 1) }}>Next Page {'>>'}</div>}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Dashboard


const Paginater = ({ totalPages, currentPage, setPage }) => {
    let pages = Array.from({ length: totalPages }, (v, k) => k + 1)
    return (
        <div className="page-button-container">
            {pages.map((page, i) => {
                return <div key={i} className={page === currentPage ? "page-button-disabled" : "page-button"} onClick={() => { setPage(page) }}>{page}</div>
            })}
        </div>
    )
}
