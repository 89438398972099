import {useState} from 'react'
import { useParams } from 'react-router-dom';
import Layout from './Components/Layout';
import Route from './Components/Pages';
import Instruction from './Components/Pages/Instruction';
import Question from './Components/Pages/Question';
import Start from './Components/Pages/Start';
import FinalScreen from "./Components/Pages/FinalScreen";
import './style.css';

const TestMate = () => {
  const {id} = useParams();
  
  const pageNames = {
    start:0,
    instruction:1,
    question: 2,
    final:3
  }

  const pages = [
    {
      endPoint:0,
      Component: Start
    },
    {
      endPoint:1,
      Component: Instruction
    },
    {
      endPoint: 2,
      Component: Question
    },
    {
      endPoint: 3,
      Component: FinalScreen
    }
  ]

  const [activePage,setActivePage] = useState(pageNames.start);

  const getPosition = (endPoint)=>{
    return endPoint - activePage 
  }

  const changePage = async(newPage)=>{
    setActivePage(newPage);
    // const timer = setInterval(()=>{
    //   setActivePage((prev)=>{
    //     if(Math.abs(prev - newPage)> 0.001){
    //       let currentState = ((prev - activePage)/(newPage - activePage));
    //       let multiple = 8;
    //       if(currentState> 0.5) multiple = 1 - currentState + 0.05;
    //       else multiple = currentState + 0.05;
    //       return prev + ((newPage - activePage)/40) * multiple;
    //     }else{
    //       clearInterval(timer)
    //       return newPage
    //     }
    //   })
    // },2)
  }

  return (
    <Layout>
      {pages.map(({endPoint,Component})=>
        <Route
          position={getPosition(endPoint)}
          key={endPoint}
          active={activePage === endPoint}
          >
          <Component
            id={endPoint===pageNames.start? id:undefined} changePage={changePage}
            pageNames={pageNames}
            active={activePage === endPoint}
            />
        </Route>
      )}
    </Layout>
  )
}

export default TestMate