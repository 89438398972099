
//importing packages
import StarRatings from 'react-star-ratings';
import { useState, useContext } from 'react';
import { useMutation } from 'react-query';

import { ChallengeContext } from 'Contexts/ChallengeContext';

//import components and services
import postData from 'Services/postData';

//import assets
import closeButton from 'assets/icons/x-circle.svg';


const SubmitChallenge = () => {
    const [rating, setRating] = useState(0);
    const [link, setLink] = useState('');
    const [comment, setComment] = useState('');
    const [linkError, setLinkError] = useState(null);
    const { challengeSubData, setChallengeSubData } = useContext(ChallengeContext);
    const mutation = useMutation((data) => postData('/user/submit', data), {
        onSuccess: (data) => {
            if (!data.status) {
                setLinkError(data.details[0].message);
            }
            else {
                setLinkError(null);
                setChallengeSubData(prev => {
                    return {
                        ...prev,
                        submitUpdate: !prev.submitUpdate,
                        submitChallengeVisibility: false
                    }
                })
            }
        },
        onError: (error) => {
        }
    });

    const submitChallenge = (e) => {
        e.preventDefault();
        mutation.mutate({
            challengeId: challengeSubData.title,
            rating: rating,
            githubLink: link,
            comment: comment
        });
    }



    return (
        <div className="popup-container">
            <div className="submit-challenge-container">
                <form onSubmit={submitChallenge}>
                    <div className="submit-form-container">
                        <label className="form-label">Github Repository Link</label>
                        <input className="form-input" type="text" value={link} onChange={(e) => { setLink(e.target.value) }} />
                    </div>
                    {linkError && <div className="error-message">{linkError}</div>}
                    <div className="submit-feedback-container">
                        <div className="feedback-title">Feedback</div>
                        <div className="submit-form-container">
                            <label className="form-label">Rating</label>
                            <StarRatings
                                rating={rating}
                                starRatedColor="#FFCC1D"
                                starHoverColor="#FFCC1D"
                                numberOfStars={5}
                                name='rating'
                                starDimension="25px"
                                changeRating={(newRating) => setRating(newRating)}
                            />
                        </div>
                        <div className="submit-form-container">
                            <label className="form-label">Comment</label>
                            <textarea className="form-textarea" type="text" value={comment} onChange={(e) => { setComment(e.target.value) }} />
                        </div>
                    </div>
                    <div className="submit-button-container">
                        <button className="common-button">Submit</button>
                    </div>
                </form>
                <div className="close-button-container" onClick={() => { setChallengeSubData(prev => { return { ...prev, submitChallengeVisibility: false } }) }}><img src={closeButton} alt="close button" className="close-button" /></div>
            </div>
        </div>

    )
}

export default SubmitChallenge
