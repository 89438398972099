const Button = ({onClick,label="START"}) => {
  return (
    <div
        className="iq-home-button"
        onClick={onClick}
    >
        {label}
    </div>
  )
}

export default Button