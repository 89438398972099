// import packages
import { useNavigate } from "react-router-dom";
import { useContext } from "react";


//importing components
import Avatar from "./Avatar"
import { UserContext } from "Contexts/UserContext";
//importing assets
import leaderboard from 'assets/icons/leaderboard.svg'
import userPlus from 'assets/icons/user-plus.svg'
import logo from 'assets/icons/Logo.svg'
import logoMobile from 'assets/icons/logo-mobile.svg'



const Header = () => {
    const { userData, setUserData } = useContext(UserContext);
    const navigate = useNavigate();

    return (
        <div className="header-container">
            <div className="header-container-section">
                <div className="logo-container" onClick={() => { navigate('/') }}>
                    <img src={logo} alt="logo" className="logo" />
                    <img src={logoMobile} alt="logo" className="logo-mobile" />
                </div>
                <div className="header-left-container">
                    <div className="leaderboard-button" onClick={() => { navigate('/leaderboard') }}>
                        <span className="leaderboard-text">Leaderboard</span> <img src={leaderboard} alt="leaderboard-icon" className="leaderboard-icon" />
                    </div>
                    {
                        userData.isLoggedin ? <div className="avatar-section">
                            <Avatar image={userData.userImg} small onClick={() => {
                                setUserData(prev => {
                                    if (prev.profilePopupVisibility) return prev
                                    return (
                                        {
                                            ...prev,
                                            profilePopupVisibility: true
                                        }
                                    )
                                })
                            }} />
                        </div> :
                            <div className="signin-button" onClick={() => {
                                setUserData(prev => {
                                    if (prev.signInPopupVisibility) return prev
                                    return (
                                        {
                                            ...prev,
                                            signinVisibility: true
                                        }
                                    )
                                })
                            }}><span className="leaderboard-text">Sign In</span><img src={userPlus} alt="user plus" /></div>
                    }

                </div>
            </div>
        </div>
    )
}

export default Header

