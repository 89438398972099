//import packages
import { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';

//importing components
import Header from "Components/Header"
import ImageToggle from "Components/ImageToggle"
import LeaderBoardComponent from 'Components/LeaderBoardComponent';
import getData from 'Services/getData';
import SubmitChallenge from 'Components/SubmitChallenge';
import { UserContext } from 'Contexts/UserContext';
import { ChallengeContext } from 'Contexts/ChallengeContext';


//import assets
import beginner from 'assets/icons/beginner.svg';
import intermediate from 'assets/icons/intermediate.svg'
import advanced from 'assets/icons/advanced.svg'
import downloadIcon from 'assets/icons/download-icon.svg';
import Procedure from 'Components/Procedure';
import ErrorScreen from 'Components/ErrorScreen';
import postData from 'Services/postData';
import Footer from 'Components/Footer';


const Challenge = () => {
    const [activeTab, setActiveTab] = useState(true);
    const [isTried, setIsTried] = useState(false);
    const [figmaUrl, setFigmaUrl] = useState('');
    const navigate = useNavigate();
    const params = useParams();
    let id = params.title;
    const { userData, setUserData } = useContext(UserContext);
    const { challengeSubData, setChallengeSubData } = useContext(ChallengeContext);
    const { data, isLoading, isError, refetch } = useQuery('challenge', () => getData(`/challenge/${id}`));

    useEffect(() => {
        if (data && data.data?.figmaLink) {
            setFigmaUrl(data.data.figmaLink);
            refetch();
        }
    }, [data, challengeSubData.submitUpdate]);

    useEffect(() => {
        const url = window.location.href;
        if (url.includes('trynow=true') && userData.isLoggedin) {
            getData(`/challenge/${id}/try`).then(response => {
                setFigmaUrl(response.data);
            });
            setIsTried(true);
            setUserData(prev => {
                return {
                    ...prev,
                    isTryNow: false
                }
            })
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }, [userData.isLoggedin]);

    if (isLoading) return <div>Loading...</div>;
    if (!data) return <ErrorScreen message="Network Error" />;
    if (isError) return <ErrorScreen />;
    if (!data.status) return <ErrorScreen message={data.message} />;
    const tryHandler = async () => {
        if (!userData.isLoggedin) {
            setUserData(prev => {
                return {
                    ...prev,
                    signinVisibility: true,
                    isTryNow: true
                }
            })
        } else {
            const response = await getData(`/challenge/${id}/try`);
            if (!response.status) return <ErrorScreen message={data.message} />
            setFigmaUrl(response.data);
            setIsTried(true);

        }
    }

    const downloadHandler = async () => {
        const response = await postData('/challenge/download', { id });
        const newDownloadLink = process.env.REACT_APP_API_URL + response.data;
        window.open(newDownloadLink);
        navigate(`/challenges/${id}`);
    }


    const toggleImage = () => {
        setActiveTab(prev => !prev);
    }
    return (
        <div className="dashboard-main-section">
            <Header />
            <div className="dashboard-main-container container">
                <div className="challenge-main-section">
                    <div className="challenge-card-image-container">
                        {data.data && activeTab ? <img src={process.env.REACT_APP_API_URL + data.data.images[0]} alt={data.data.title} className="challenge-card-image challenge-image" /> : <img src={process.env.REACT_APP_API_URL + data.data.images[1]} alt={data.data.title} className="challenge-card-image challenge-image" />}
                        <ImageToggle activeTab={activeTab} onClick={() => { toggleImage() }} />
                    </div>
                    <div className="challenge-info">
                        <div className="challenge-card-title">{data.data.title}</div>
                        <div className="challenge-button-container">
                            {data.data.status === 'new' && !isTried ? <button className="common-button" onClick={tryHandler}>Try Now</button>
                                : <>
                                    {data.data.status === 'submit' ? <button
                                        className="common-button submit-button download-button"
                                        disabled>Submitted</button>
                                        : data.data.status === 'complete' ? <div
                                            className="submit-button download-button common-button">Score: <span style={{ fontWeight: 'bold' }}>{data.data.score}</span></div>
                                            : <button className="common-button submit-button"
                                                onClick={() => setChallengeSubData(prev => { return { ...prev, submitChallengeVisibility: true, title: id } })}>Submit</button>}
                                    <a className="common-button figma-button" href={figmaUrl} target="_blank">View Design</a>
                                    <button className="common-button download-button" onClick={() => downloadHandler()}>Download <img src={downloadIcon} alt="download" className="download-icon" /></button></>
                            }

                        </div>
                        <div className="challenge-card-sub-container">
                            <div className="challenge-card-type"><img
                                src={data.data.difficulty === "Beginner" ? beginner : data.data.difficulty === "Intermediate" ? intermediate : advanced}
                                alt="type symbol"
                                className="type-symbol" />{data.data.difficulty}</div>
                            {data.data.isJs && <div className="challenge-card-type challenge-card-js">JS</div>}
                            <div className="challenge-card-date">{data.data.postedAt}</div>
                        </div>
                        <div className="challenge-card-description">
                            {data.data.description}
                        </div>
                    </div>
                </div>
                <Procedure />
                <LeaderBoardComponent leaderboard={data.data.leaderboard} score />
            </div>
            {challengeSubData.submitChallengeVisibility && <SubmitChallenge />}
            <Footer />
        </div>
    )
}

export default Challenge
