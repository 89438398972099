//importing packages
import { useContext } from "react";

import { UserContext } from "Contexts/UserContext";

//importing components
import Avatar from "./Avatar"
import levelEmblem from "Helper/levelEmblem";

//importing assets



const ProfileView = ({ name, createdAt, score, rank, level, medium, image }) => {
    const { userData } = useContext(UserContext);
    let levelColor = "";
    switch (level) {
        case 'Legend':
            levelColor = "#FF0000";
            break;
        case 'Elite Master':
            levelColor = "#38C9D1";
            break;
        case 'Master':
            levelColor = "#A8ABFC";
            break;
        case 'Elite Champion':
            levelColor = "#FD00B7";
            break;
        case 'Champion':
            levelColor = "#FF65D4";
            break;
        case 'Veteran':
            levelColor = "#65FFED";
            break;
        case 'Knight':
            levelColor = "#F4CF0D";
            break;
        case 'Apprentice':
            levelColor = "#E4E4E4";
            break;
        case 'Recruit':
            levelColor = "#A6671D";
            break;
        default:
            return null;
    }


    return (
        <div className="profileview-container">
            <div className="profile-details">
                {medium ? <Avatar medium image={image ? image : userData.userImg} level={level} /> : <Avatar image={image ? image : userData.userImg} level={level} />}
                <div className="profile-details-section">
                    <div className="profile-name">{name}</div>
                    <div className="member-since">Member since: {createdAt}</div>
                    {score || score === 0 ? <div className="profile-score"><span className="score-label">Score:</span>{score}</div> : <div></div>}
                    <div className="profile-rank"># {rank}</div>
                </div>
            </div>
            <div className="profile-level">
                <img src={levelEmblem(level)} alt="level" className="profile-level-image" />
                <div className="profile-level-title" style={{ color: levelColor, textTransform: "uppercase" }}>{level}</div>
            </div>
        </div>
    )
}

export default ProfileView
