import question1 from "../Assets/Images/question_1.jpg";
import question1_A from "../Assets/Images/question_1_option_A.jpg";
import question1_B from "../Assets/Images/question_1_option_B.jpg";
import question1_C from "../Assets/Images/question_1_option_C.jpg";
import question1_D from "../Assets/Images/question_1_option_D.jpg";
import question1_E from "../Assets/Images/question_1_option_E.jpg";
import question11 from "../Assets/Images/question_11.png";
import question18_A from "../Assets/Images/2a.jpg";
import question18_B from "../Assets/Images/2b.jpg";
import question18_C from "../Assets/Images/2c.png";
import question18_D from "../Assets/Images/2d.jpg";
import question18_E from "../Assets/Images/2e.jpg";
import question20_A from "../Assets/Images/q3.jpg";
import question20_B from "../Assets/Images/3a.jpg";
import question20_C from "../Assets/Images/3b.jpg";
import question20_D from "../Assets/Images/3c.jpg";
import question20_E from "../Assets/Images/3d.jpg";
import question20_F from "../Assets/Images/3e.jpg";
import question23_A from "../Assets/Images/4q.jpg";
import question23_B from "../Assets/Images/4a.jpg";
import question23_C from "../Assets/Images/4b.jpg";
import question23_D from "../Assets/Images/4c.jpg";
import question23_E from "../Assets/Images/4d.jpg";
import question23_F from "../Assets/Images/4e.jpg";
import question26_A from "../Assets/Images/5a.jpg";
import question26_B from "../Assets/Images/5b.jpg";
import question26_C from "../Assets/Images/5c.jpg";
import question26_D from "../Assets/Images/5d.jpg";
import question26_E from "../Assets/Images/5e.jpg";
import question28_A from "../Assets/Images/6a.png";
import question28_B from "../Assets/Images/6b.png";
import question29_A from "../Assets/Images/6q.jpg";
import question29_B from "../Assets/Images/6a.jpg";
import question29_C from "../Assets/Images/6b.jpg";
import question29_D from "../Assets/Images/6c.jpg";
import question29_E from "../Assets/Images/6d.jpg";
import question12_A from "../Assets/Images/10a.png";
import question12_B from "../Assets/Images/11a.jpg";
import question12_C from "../Assets/Images/11b.jpg";
import question12_D from "../Assets/Images/11c.jpg";
import question12_E from "../Assets/Images/11d.jpg";
import question12_F from "../Assets/Images/11e.jpg";
import question16_A from "../Assets/Images/16q.png";
import question16_B from "../Assets/Images/16a.png";
import question16_C from "../Assets/Images/16b.png";
import question16_D from "../Assets/Images/16c.png";
import question16_E from "../Assets/Images/16d.png";
import question16_F from "../Assets/Images/16e.png";

export const contentType = {
  text: 1,
  image: 2,
};

export const answerTypes = {
  option: 0,
  listSelection: 1,
  textBox: 2,
  textArea: 3,
  puzzleTable: 4,
  fillInBlank: 5,
};

export default [
  {
    question: [
      {
        type: contentType.text,
        data: "Which set of symbols should replace the question mark?",
      },
      {
        type: contentType.image,
        data: question1,
      },
    ],
    answers: [
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question1_A,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question1_B,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question1_C,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question1_D,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question1_E,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "Select two words that are synonyms,plus an antonym of these two synonyms,from the list of words below.",
      },
      {
        type: contentType.text,
        data: "Chock, Force, Thrive, Toil, Wither, Burgeon, Strive",
      },
    ],
    answers: [
      {
        answerType: answerTypes.listSelection,
        type: contentType.text,
        data: "Synonyms",
        list: [
          "Chock",
          "Force",
          "Thrive",
          "Toil",
          "Wither",
          "Burgeon",
          "Strive",
        ],
        numberOfSelection: 2,
      },
      {
        answerType: answerTypes.listSelection,
        type: contentType.text,
        data: "Antonyms",
        list: [
          "Chock",
          "Force",
          "Thrive",
          "Toil",
          "Wither",
          "Burgeon",
          "Strive",
        ],
        numberOfSelection: 1,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "Which two numbers should replace the question marks?",
      },
      {
        type: contentType.text,
        data: "19 , 20 , 21 , ? , ? , 26 , 28 , 32 , 33 , 40",
      },
    ],
    answers: [
      {
        answerType: answerTypes.textBox,
        numberOfTextBox: 2,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "The institution houses collections of objects of artistic, historic and scientific interest, and displayed for the edification and enjoyment of the public.",
      },
      {
        type: contentType.text,
        data: " One word has been removed from the passage above. Select that word from the choice below and reinstate it into its correct place in the passage.",
      },
      {
        type: contentType.text,
        data: "a. huge, b. permanent, c. produced, d. conserved, e. priceless f. accumulated",
      },
    ],
    answers: [
      {
        answerType: answerTypes.textArea,
        numberOfTextArea: 1,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "Which is the odd one out?",
      },
      {
        type: contentType.text,
        data: "congregation, dispersion, compilation, convocation, aggregation",
      },
    ],
    answers: [
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "Congregation",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "Dispersion",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "Compilation",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "Convocation",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "Aggregation",
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "Ω # – Ω Ω # # – ? Ω # – Ω Ω # # – = # Ω #",
      },
      {
        type: contentType.text,
        data: "– Ω Ω # # – ? Ω # – Ω Ω # # – = # Ω # – Ω",
      },
      {
        type: contentType.text,
        data: "Ω # # – ? Ω # – Ω Ω # # – Ω # – Ω Ω # # – ?",
      },
      {
        type: contentType.text,
        data: "Ω # – Ω Ω # # – = #",
      },
      {
        type: contentType.text,
        data: "Which two symbols are missing?",
      },
    ],
    answers: [
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "Ω #",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "– Ω",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "# #",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "= #",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "- =",
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "Which number is the odd one out?",
      },
      {
        type: contentType.text,
        data: "84129, 32418, 47632, 36119, 67626, 72927",
      },
    ],
    answers: [
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "84129",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "32418",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "47632",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "36119",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "67626",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "72927",
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "Identify two words (one from each set of brackets) that form a connection (analogy), thereby relating to the words in capitals in the same way.",
      },
      {
        type: contentType.text,
        data: "CAT (lash, parade, feline, whiskers)",
      },
      {
        type: contentType.text,
        data: "SLEEP (somnambulate, night, bed, Morpheus)",
      },
    ],
    answers: [
      {
        answerType: answerTypes.listSelection,
        type: contentType.text,
        data: "",
        list: ["Lash", "Parade,", "Feline", "Whiskers"],
        numberOfSelection: 1,
      },
      {
        answerType: answerTypes.listSelection,
        type: contentType.text,
        data: "",
        list: ["Somnambulate,", "night", "bed", "Morpheus"],
        numberOfSelection: 1,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "Which word in brackets is closest in meaning to the word in capitals?",
      },
      {
        type: contentType.text,
        data: "FLIPPANT (obverse, irreverent, feeble, candid, facile)",
      },
    ],
    answers: [
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "Obverse",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "Irreverent",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "Feeble",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "Candid",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "Facile",
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "12593 is to 35291 and 29684 is to 46982 therefore 72936 is to ?",
      },
    ],
    answers: [
      {
        answerType: answerTypes.textBox,
        numberOfTextBox: 1,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.image,
        data: question11,
      },
      {
        type: contentType.text,
        data: "Complete the equation by correctly identifying the missing part of the calculation from the list of options below.",
      },
    ],
    answers: [
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "0.75",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "1.95 - 0.75",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "0.5",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "0.825",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "0.25",
      },
    ],
  },
  {
    question: [
      {
        type: contentType.image,
        data: question12_A,
      },
      {
        type: contentType.text,
        data: "Draw the contents of the middle tile in accordance with the rules of logic already established.",
      },
    ],
    answers: [
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question12_B,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question12_C,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question12_D,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question12_E,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question12_F,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "Insert numbers into the remaining blank squares so that the sums in each line and column are correct. All numbers to be inserted are less than 10.",
      },
    ],
    answers: [
      {
        answerType: answerTypes.puzzleTable,
        data: [
          ["", "÷", "", "×", "", "=", "6"],
          ["+", null, "-", null, "×", null, "+"],
          ["", "+", "", "-", "4", "=", ""],
          ["÷", null, "+", null, "-", null, "÷"],
          ["", "×", "3", "-", "", "=", ""],
          ["=", null, "=", null, "=", null, "="],
          ["", "÷", "", "+", "", "=", ""],
        ],
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "Identify a pair of anagrams from the list of words below.",
      },
      {
        type: contentType.text,
        data: "intercom, carolean, fornical, landrace, alderman, maladies, parlance, calendar, marlined, miracles, minerals, confined, barnacle",
      },
    ],
    answers: [
      {
        answerType: answerTypes.listSelection,
        type: contentType.text,
        data: "",
        list: [
          "intercom",
          "carolean",
          "fornical",
          "landrace",
          "alderman",
          "maladies",
          "parlance",
          "calendar",
          "marlined",
          "miracles",
          "minerals",
          "confined",
          "barnacle",
        ],
        numberOfSelection: 2,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "A Z B Y D W G T ?",
      },
      {
        type: contentType.text,
        data: "Which two letters come next?",
      },
    ],
    answers: [
      {
        answerType: answerTypes.textBox,
        numberOfTextBox: 2,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.image,
        data: question16_A,
      },
      {
        type: contentType.text,
        data: "The top set of six numbers has a relationship to the set of six numbers below. The two sets of six boxes on the left have the same relationship as the two sets of six boxes on the right. Which set of numbers should therefore replace the question marks?",
      },
    ],
    answers: [
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question16_B,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question16_C,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question16_D,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question16_E,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question16_F,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "How many cases do you need if you have to pack 112 pairs of shoes into cases that each hold 28 shoes?",
      },
    ],
    answers: [
      {
        answerType: answerTypes.textBox,
        numberOfTextBox: 1,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "Which is the odd one out?",
      },
    ],
    answers: [
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question18_A,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question18_B,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question18_C,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question18_D,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question18_E,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "In the two numerical sequences below, one number that appears in the top sequence should appear in the bottom sequence and vice versa. Which two numbers should be changed round?",
      },
      {
        type: contentType.text,
        data: "2, 2.5, 4.5, 6.75",
      },
      {
        type: contentType.text,
        data: "1, 3, 6.25, 15.625",
      },
    ],
    answers: [
      {
        answerType: answerTypes.listSelection,
        type: contentType.text,
        data: "",
        list: ["2", "2.5", "4.5", "6.75"],
      },
      {
        answerType: answerTypes.listSelection,
        type: contentType.text,
        data: "",
        list: ["1", "3", "6.25", "15.625"],
        numberOfSelection: 1,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.image,
        data: question20_A,
      },
      {
        type: contentType.text,
        data: "Which two squares are missing?",
      },
    ],
    answers: [
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question20_B,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question20_C,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question20_D,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question20_E,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question20_F,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "Identify two words (one from each set of brackets) that form a connection (analogy), thereby relating to the words in capitals in the same way.",
      },
      {
        type: contentType.text,
        data: "FRONT (inverse, ulterior, anterior, contraverse, obverse)",
      },
      {
        type: contentType.text,
        data: "FACE (exterior, converse, countenance, obverse)",
      },
    ],
    answers: [
      {
        answerType: answerTypes.listSelection,
        type: contentType.text,
        data: "FRONT",
        list: ["inverse,", "ulterior,", "anterior,", "contraverse,", "obverse"],
        numberOfSelection: 1,
      },
      {
        answerType: answerTypes.listSelection,
        type: contentType.text,
        data: "FACE",
        list: ["exterior,", "converse,", "countenance,", "obverse"],
        numberOfSelection: 1,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "Change the position of four words only in the sentence below in order for it to make complete sense.",
      },
      {
        type: contentType.text,
        data: "If you are printing on glossy paper or transparencies, place a support stack, or a sheet of plain media, beneath the sheet, or load only one sheet at a time.",
      },
    ],
    answers: [
      {
        answerType: answerTypes.textArea,
        type: contentType.text,
        data: "",
        numberOfTextArea: 1,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.image,
        data: question23_A,
      },
      {
        type: contentType.text,
        data: "Complete the equation by correctly identifying the missing part of the calculation from the list of options below.",
      },
    ],
    answers: [
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question23_B,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question23_C,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question23_D,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question23_E,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question23_F,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "Which two words are most opposite in meaning?",
      },
      {
        type: contentType.text,
        data: "acquired, derivative, archetypal, elaborate, enigmatic,spasmodic",
      },
    ],
    answers: [
      {
        answerType: answerTypes.listSelection,
        type: contentType.text,
        data: "",
        list: [
          "acquired",
          "derivative",
          "archetypal",
          "elaborate",
          "enigmatic",
          "spasmodic",
        ],
        numberOfSelection: 2,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "Select two words that are synonyms, plus an antonym of these two synonyms, from the list of words below.",
      },
      {
        type: contentType.text,
        data: "excuse, regulate, bestow, condone, concede, condemn,incarcerate",
      },
    ],
    answers: [
      {
        answerType: answerTypes.listSelection,
        type: contentType.text,
        data: "Synonyms",
        list: [
          "excuse",
          "regulate",
          "bestow",
          "condone",
          "concede",
          "condemn",
          "incarcerate",
        ],
        numberOfSelection: 2,
      },
      {
        answerType: answerTypes.listSelection,
        type: contentType.text,
        data: "Antonym",
        list: [
          "excuse",
          "regulate",
          "bestow",
          "condone",
          "concede",
          "condemn",
          "incarcerate",
        ],
        numberOfSelection: 1,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "Which is the odd one out?",
      },
    ],
    answers: [
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question26_A,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question26_B,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question26_C,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question26_D,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question26_E,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "If 4 apples and 6 bananas cost £1.56 and 9 apples and 7 bananas cost £2.60, what is the cost of one apple and one banana?",
      },
    ],
    answers: [
      {
        answerType: answerTypes.textBox,
        type: contentType.text,
        data: "",
        numberOfTextBox: 1,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "An electrical circuit wiring a set of four lights depends on a system of switches A, B, C and D. Each switch when working has the following effect on the lights:",
      },
      {
        type: contentType.text,
        data: "Switch A turns lights 1 and 2 on/off or off/on",
      },
      {
        type: contentType.text,
        data: "Switch B turns lights 2 and 4 on/off or off/on",
      },
      {
        type: contentType.text,
        data: "Switch C turns lights 1 and 3 on/off or off/on",
      },
      {
        type: contentType.text,
        data: "Switch D turns lights 3 and 4 on/off or off/on",
      },
      {
        type: contentType.image,
        data: question28_A,
      },
      {
        type: contentType.text,
        data: "In the following, switches C B D A are thrown in turn, with the result that Figure 1 is transformed into Figure 2. One of the switches is therefore not working and has had no effect on the numbered lights.",
      },
      {
        type: contentType.text,
        data: "Identify which one of the switches is not working.",
      },
      {
        type: contentType.image,
        data: question28_B,
      },
    ],
    answers: [
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "Switch A",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "Switch B",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "Switch C",
      },
      {
        answerType: answerTypes.option,
        type: contentType.text,
        data: "Switch D",
      },
    ],
  },
  {
    question: [
      {
        type: contentType.image,
        data: question29_A,
      },
      {
        type: contentType.text,
        data: "Which is the missing section?",
      },
    ],
    answers: [
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question29_B,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question29_C,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question29_D,
      },
      {
        answerType: answerTypes.option,
        type: contentType.image,
        data: question29_E,
      },
    ],
  },
  {
    question: [
      {
        type: contentType.text,
        data: "_______ _______ is used for a _______ variety of _______, including the _______ of _______ data for oil and _______ _______, the _______ of new _______ designs, the processing of _______ _______, and the _______ of data from _______ _______.",
      },
      {
        type: contentType.text,
        data: "Insert the 15 words below into their correct position in the above passage.",
      },
      {
        type: contentType.text,
        data: "analysis, analysis, applications, data, experiments, exploration, imagery, mineral, processing, processing, product, satellite, seismic, scientific, wide",
      },
    ],
    answers: [
      {
        answerType: answerTypes.fillInBlank,
        type: contentType.text,
        data: [
          "",
          "",
          "is used for a",
          "",
          "variety of",
          "",
          ", data for oil and",
          "",
          "of",
          "",
          "designs, the processing of",
          "",
          "",
          ", and the",
          "",
          "of data from",
          "",
          "",
          ". ",
        ],
        numberOfTextBox: 1,
      },
    ],
  },
];
