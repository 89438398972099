const Route = ({ children,active }) => {
  return (
    <div
      className={`mark-it-page-container ${active? "page-container-active":""}`}
    >
      {children}
    </div>
  );
};

export default Route;
