import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './Components/Layout';
import Home from './Home';
import Instructions from './Home/Instructions';
import Question from './Question';
import "./style.css";
import answers from './Data/answers';
import Finish from './Question/Finish';

const IqTest = () => {
  const [answersList, setAnswersList] = useState(answers);
  const [isExamStarted, setIsExamStarted] = useState(false);

  return (
    <Routes >
        <Route path="/" element={<Layout/>} >
            <Route index element={<Home/>} />
            <Route path="instructions" element={<Instructions setIsExamStarted={setIsExamStarted}/>} />
            <Route path="question/:id" element={
              <Question
                answers={answersList}
                setAnswersList={setAnswersList}
                isExamStarted={isExamStarted}
              />} />
            
            <Route path="finish" element={<Finish setIsExamStarted={setIsExamStarted}/>} />
        </Route>
    </Routes>
  )
}

export default IqTest