import React from 'react'

const Button = ({label,type}) => {
  return (
    <div className='mark-it-button'>
        <button type={type}>
            {label}
        </button>
    </div>
  )
}

export default Button