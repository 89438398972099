import postCall from "../../Services/postCall"
import { useState } from "react"
import Button from "../Inputs/Button"
import { useNavigate } from "react-router-dom"

const Start = ({changePage,pageNames,id}) => {

  const [email,setEmail] = useState("");
    const navigate = useNavigate();
    
    const getToken = async()=>{
        const result = await postCall("/user/get-token",{email});
        if(!result.status)return alert("Email not found");
        localStorage.setItem("test-token",result.token);
        changePage(pageNames.instruction);
    }
  return (
    <div className="starting-page-container">
        <h1 className='test-mate-title'>ONLINE EXAM</h1>
        <p className='test-mate-normal-text'>
        Welcome to online aptitude test of Ionaught Technologies, this is aimed at evaluating your professional abilities. The test will last exact 30 minutes. Please take your time to read all instructions carefully on the next screen before starting the exam.
        </p>
        <div
          className="start-input-container"
        >
          <input
            type="text"
            placeholder="Email"
            value={email}
            className="test-mate-input"
            onChange={(e) => setEmail(e.target.value)}
            />
        </div>
        <Button label={"Start"} onClick={getToken} />
    </div>
  )
}

export default Start