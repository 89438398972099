// import packages
import { useState, useEffect } from 'react';

//importing assets
import beginner from 'assets/icons/beginner.svg'
import intermediate from 'assets/icons/intermediate.svg'
import advanced from 'assets/icons/advanced.svg'
import settingIcon from 'assets/icons/setting-icon.svg'

//import components
import ToggleButton from './ToggleButton';

const SearchSortFilter = ({ searchSortData, setSearchSortData }) => {
    const [searchText, setSearchText] = useState('');
    const [sortDifficultVisibility, setSortDifficultVisibility] = useState(true);

    window.addEventListener('resize', (e) => {
        if (window.innerWidth < 480) {
            setSortDifficultVisibility(false);
        } else {
            setSortDifficultVisibility(true);
        }
    });

    useEffect(() => {
        if (window.innerWidth < 480) {
            setSortDifficultVisibility(false);
        } else {
            setSortDifficultVisibility(true);
        }
    }, []);

    const onChange = (key, value) => {
        setSearchSortData(prev => {
            return {
                ...prev,
                [key]: value
            }
        })
    }

    return (
        <div className="search-sort-filter-container">
            <div className="search-sort-container">
                <div className="search-input-container">
                    <div className="search-input-section">
                        <input type="text" className="search-input" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                        <button className="search-button" onClick={() => { onChange('search', searchText) }}>Search</button></div>
                    <div className="setting-icon-container" onClick={() => { setSortDifficultVisibility(prev => !prev) }}>
                        <img src={settingIcon} alt="setting-icon" className="setting-icon" />
                    </div>
                </div>
                {sortDifficultVisibility && <div className="sort-container">
                    <label className="label-text">Sort By</label>
                    <select name="sort" className="sort-values" onChange={(e) => { onChange('sort', e.target.value) }}>
                        <option value="Recent">Recent</option>
                        <option value="Popularity" >Popularity</option>
                    </select>
                </div>}
            </div>
            {sortDifficultVisibility && <div className="difficuilty-js-container">
                <div className="difficulty-container">
                    <label className="label-text">Difficulty</label>
                    <div className="difficuilty-button-container">
                        <button
                            className={`difficulty-button ${searchSortData.activeDifficulty === 'all' && 'difficulty-button-active'}`}
                            onClick={() => setSearchSortData(prev => { return { ...prev, activeDifficulty: 'all' } })}
                        >All</button>
                        <button
                            className={`difficulty-button ${searchSortData.activeDifficulty === 'beginner' && 'difficulty-button-active'}`}
                            onClick={() => setSearchSortData(prev => { return { ...prev, activeDifficulty: 'beginner' } })}
                        ><img src={beginner} className="difficuilty-symbol" alt="beignner" /></button>
                        <button
                            className={`difficulty-button ${searchSortData.activeDifficulty === 'intermediate' && 'difficulty-button-active'}`}
                            onClick={() => setSearchSortData(prev => { return { ...prev, activeDifficulty: 'intermediate' } })}
                        ><img src={intermediate} className="difficuilty-symbol" alt="intermediate" /></button>
                        <button
                            className={`difficulty-button ${searchSortData.activeDifficulty === 'advanced' && 'difficulty-button-active'}`}
                            onClick={() => setSearchSortData(prev => { return { ...prev, activeDifficulty: 'advanced' } })}
                        ><img src={advanced} className="difficuilty-symbol" alt="advanced" /></button>
                    </div>
                </div>
                <div className="js-container">
                    <label className="label-text">JS Included</label>
                    <ToggleButton onChange={(value) => { onChange('isNoJs', value) }} value={searchSortData.isNoJs} />
                </div>
            </div>}
        </div>
    )
}

export default SearchSortFilter
