import React from 'react'
import { Outlet } from 'react-router-dom'
import ionaughtLogo from "../../Assets/ionaught-logo.svg";
const Layout = () => {
  return (
    <div className='iq-test-screen'>
      <div className="iq-test-container">
        <img src={ionaughtLogo} alt="Ionaught" className='iq-test-logo' />
        <div className="iq-test-window">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Layout