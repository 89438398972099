import React from 'react'

const SelectBox = ({list,value,onChange}) => {

  return (
    <div className='iq-test-select-box-container'>
        <select value={value} onChange={e=>onChange(e.target.value)}>
            <option value="">Select Option</option>
            {list.map((text,index)=><option key={index} value={text}>{text}</option>)}
        </select>
    </div>
  )
}

export default SelectBox