import { useEffect,useState } from "react"
import getCall from "./Services/getCall";

const Result = () => {
    const [result,setResult] = useState([]);
    useEffect(()=>{loadResult()},[]);
    const loadResult = async()=>{
        const result = await getCall("/admin/get-results");
        setResult(result.result);
    }
  return (
    <div className="test-mate-result-screen">
        <div className="result-header">
            Name
        </div>
        <div className="result-header">
            Email
        </div>
        <div className="result-header">
            Question Attended
        </div>
        <div className="result-header">
            Score
        </div>
        {result.map(({name,numberOfQuestionsAttended,score,email})=>{
            return(
                <>
                <div className="result-body">
                    {name}
                </div>
                <div className="result-body">
                    {email}
                </div>
                <div className="result-body">
                    {numberOfQuestionsAttended}
                </div>
                <div className="result-body">
                    {score}
                </div>
                </>
            )
        })}
    </div>
  )
}

export default Result