//import packages
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//import assets
import legend from 'assets/icons/legend.svg';
import eliteMaster from 'assets/icons/elite-master.svg';
import master from 'assets/icons/master.svg';
import eliteChampion from 'assets/icons/elite-champion.svg';
import champion from 'assets/icons/champion.svg';
import veteran from 'assets/icons/veteran.svg';
import knight from 'assets/icons/knight.svg';
import apprentice from 'assets/icons/apprentice.svg';
import recruit from 'assets/icons/recruit.svg';

const LeaderBoardComponent = ({ leaderboard }) => {
    const [leaderboardList, setLeaderboardList] = useState([
        {
            rank: 1,
            name: 'John Doe',
            level: 'Legend',
            score: '100',
            completedChallenges: '10',
        },
        {
            rank: 2,
            name: 'John Doe',
            level: 'Elite Master',
            score: '100',
            completedChallenges: '10',
        },
        {
            rank: 3,
            name: 'John Doe',
            level: 'Master',
            score: '100',
            completedChallenges: '10',
        },
        {
            rank: 4,
            name: 'John Doe',
            level: 'Elite Champion',
            score: '100',
            completedChallenges: '10',
        },
        {
            rank: 5,
            name: 'John Doe',
            level: 'Champion',
            score: '100',
            completedChallenges: '10',
        },
        {
            rank: 6,
            name: 'John Doe',
            level: 'Veteran',
            score: '100',
            completedChallenges: '10',
        },
        {
            rank: 7,
            name: 'John Doe',
            level: 'Knight',
            score: '100',
            completedChallenges: '10',
        },
        {
            rank: 8,
            name: 'John Doe',
            level: 'Apprentice',
            score: '100',
            completedChallenges: '10',
        },
        {
            rank: 9,
            name: 'John Doe',
            level: 'Recruit',
            score: '100',
            completedChallenges: '10',
        },
    ]);
    const navigate = useNavigate();
    useEffect(() => {
        setLeaderboardList(leaderboard);
    }, [leaderboard]);



    const levelEmblem = (level) => {
        switch (level) {
            case 'Legend':
                return legend;
            case 'Elite Master':
                return eliteMaster;
            case 'Master':
                return master;
            case 'Elite Champion':
                return eliteChampion;
            case 'Champion':
                return champion;
            case 'Veteran':
                return veteran;
            case 'Knight':
                return knight;
            case 'Apprentice':
                return apprentice;
            case 'Recruit':
                return recruit;
            default:
                return null;
        }
    };
    return (
        <div className="leaderboard-container">
            <div className="leaderboard-title">LEADERBOARD</div>
            <div className="leaderboard-list-container">
                {leaderboardList.length === 0 && <h3 style={{ textAlign: 'center' }}>No Participants Yet</h3>}
                {leaderboardList.map(({ rank, name, level, completedChallenges, score, id }, i) => {
                    return (
                        <div className={`${i === 0 ? 'leaderboard-list-gold' : i === 1 ? 'leaderboard-list-silver' : i === 2 ? 'leaderboard-list-bronze' : 'leaderboard-list-item'}`}>
                            <div className="leaderboard-list-item-left">
                                <div className="leaderboard-list-item-rank">{rank}</div>
                                <div className="leaderboard-rank-emblem-container">
                                    <img src={levelEmblem(level)} alt="emblem" className="leaderboard-rank-emblem" />
                                </div>
                                <div className="leaderboard-list-item-name" onClick={() => { navigate(`/${id}`) }}>{name}</div>
                            </div>
                            <div className="leaderboard-list-item-right">
                                {score ? <div className="leaderboard-list-item-score"><span style={{ fontSize: '0.8rem', color: '#706e6e' }}>Score: </span>{score}</div>
                                    : <div className="leaderboard-list-item-score"><span style={{ fontSize: '0.8rem', color: '#706e6e' }}>Completed: </span>{completedChallenges}</div>}
                            </div>
                        </div>)
                })}
            </div>
        </div>
    )
}

export default LeaderBoardComponent
