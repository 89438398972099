import React from 'react'

const FinalScreen = () => {
  return (
    <div className='instruction-page-container'>
        <h1 className='page-title'>Your Answers Submitted Successfully!</h1>
        <p className='test-mate-normal-text'>
        Thank you for participating the test.
        </p>       
    </div>
  )
}

export default FinalScreen