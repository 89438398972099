import postCall from 'Pages/TestMate/Services/postCall';
import { useEffect,useState } from 'react'
import Button from '../Inputs/Button'
import CheckBox from '../Inputs/CheckBox'

const Question = ({changePage,pageNames,active}) => {
    const[questionNumber,setQuestionNumber] = useState(1);
    const [timeLimit,setTimeLimit] = useState(30*60);
    const[question,setQuestion] = useState(null);
    const[answer,setAnswer] = useState("");
    let totalQuestions = 25;
    useEffect(()=>{
        if(!active) return
        const timer = setInterval(()=>{
            setTimeLimit(prev=>{
                if(prev ===0){
                    clearInterval(timer)
                    return 0
                }
                return prev-1
            })
        },1000)
    },[active])

    useEffect(()=>{
        if(timeLimit)return;
        changePage(pageNames.final)
    },[timeLimit]);

    useEffect(()=>{
        if(!active) return
        loadQuestion();
    },[active,questionNumber]);

    const loadQuestion = async()=>{
        const result = await postCall("/user/get-question",{page:questionNumber});
        if(!result.status)return alert("failed to load question");
        setQuestion(result.question);
    }

    const sendAnswer = async()=>{
        if(answer){
          setAnswer(""); 
          const result = await postCall("/user/update-answer",{
                questionId: question.id,
                answer
            })
        if(result.status){
            if(questionNumber === totalQuestions){
                return changePage(pageNames.final)
            }
            setQuestionNumber(questionNumber+1);
        }
        }
    }

    const skipQuestion = ()=>{
        if(questionNumber === totalQuestions){
            return changePage(pageNames.final)
        }
        setQuestionNumber(questionNumber+1);
        setAnswer("");
    }

  return (
    <div className='question-screen-container'>
        <div className="question-screen-header">
            <div className="question-number">
                {questionNumber}/{totalQuestions}
            </div>
            <div className="question-time-remaining">
                {Math.floor(timeLimit/60)}:{Math.floor(timeLimit%60)<10 && 0}{Math.floor(timeLimit%60)}
            </div>
        </div>
        <div>
            <div className="test-mate-question">
                {question?.number}{". "}{question?.question}
            </div>
            <div className="test-mate-options">
                {question?.options.map((option,i)=>{
                    return <CheckBox
                        key={i}
                        option={i+1}
                        label={option}
                        id={question.id}
                        value={answer}
                        onClick={()=>{setAnswer(option)}}
                        />
                })
                }
            </div>
        </div>
        <Button
            label={"Next"}
            onClick={sendAnswer}
            disabled={!answer}
        />
        <div
            className="test-app-skip-button"
            onClick={skipQuestion}
        >
            Skip
        </div>
    </div>
  )
}

export default Question