
const TextArea = ({value,onChange}) => {
  return (
    <div className='iq-test-text-area-container'>
        <textarea
            rows="4"
            cols="50"
            value={value}
            onChange={(e)=>onChange(e.target.value)}
        />
    </div>
  )
}

export default TextArea