//importing assets
import mobileWhite from 'assets/icons/mobile-white.svg';
import mobileBlack from 'assets/icons/mobile-black.svg';
import pcWhite from 'assets/icons/pc-white.svg';
import pcBlack from 'assets/icons/pc-black.svg';

const ImageToggle = ({ activeTab, onClick }) => {
    return (
        <div className="image-toggle-container" >
            {activeTab ? <img src={pcWhite} alt="desktop" className="toggle-icon" /> : <img src={pcBlack} alt="desktop" className="toggle-icon" onClick={onClick} />}
            {!activeTab ? <img src={mobileWhite} alt="mobile" className="toggle-icon" /> : <img src={mobileBlack} alt="mobile" className="toggle-icon" onClick={onClick} />}
        </div>
    )
}

export default ImageToggle
