export const answerOptions = {
  A: 0,
  B: 1,
  C: 2,
  D: 3,
  E: 4,
};

export default [
  {
    option: null,
  },
  {
    selectList: [["", ""], [""]],
  },
  {
    textList: [["", ""]],
  },
  {
    textList: [
      [
        "The institution houses collections of objects of artistic, historic and scientific interest, and displayed for the edification and enjoyment of the public.",
      ],
    ],
  },
  {
    option: null,
  },
  {
    option: null,
  },
  {
    option: null,
  },
  {
    selectList: [[""], [""]],
  },
  {
    option: null,
  },
  {
    textList: [[""]],
  },
  {
    option: null,
  },
  {
    option: null,
  },
  {
    puzzleList: [
      ["", "÷", "", "×", "", "=", "6"],
      ["+", null, "-", null, "×", null, "+"],
      ["", "+", "", "-", "4", "=", ""],
      ["÷", null, "+", null, "-", null, "÷"],
      ["", "×", "3", "-", "", "=", ""],
      ["=", null, "=", null, "=", null, "="],
      ["", "÷", "", "+", "", "=", ""],
    ],
  },
  {
    selectList: [["", ""]],
  },
  {
    textList: [[""], [""]],
  },
  {
    option: null,
  },
  {
    textList: [[""]],
  },
  {
    option: null,
  },
  {
    selectList: [[""], [""]],
  },
  {
    option: null,
  },
  {
    selectList: [[""], [""]],
  },
  {
    textList: [
      [
        "If you are printing on glossy paper or transparencies, place a support stack, or a sheet of plain media, beneath the sheet, or load only one sheet at a time.",
      ],
    ],
  },
  {
    option: null,
  },
  {
    selectList: [["", ""]],
  },
  {
    selectList: [["", ""], [""]],
  },
  {
    option: null,
  },
  {
    textList: [[""]],
  },
  {
    option: null,
  },
  {
    option: null,
  },
  {
    fillList: [
      [
        "",
        "",
        "is used for a",
        "",
        "variety of",
        "",
        ", data for oil and",
        "",
        "of",
        "",
        "designs, the processing of",
        "",
        "",
        ", and the",
        "",
        "of data from",
        "",
        "",
        ". ",
      ],
    ],
  },
];
