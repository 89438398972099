import Button from "../Components/Button"

const Layout = ({title,children,onStart,illustration}) => {
  return (
    <div className="iq-home-container">
        <div className="iq-home-left-section">
            <div className="iq-home-left-container">
                <h1 className="iq-home-title">
                    {title}
                </h1>
                {children}
                
            </div>
        </div>
        <div className="iq-home-right-section">
            <img src={illustration} alt="Home" />
            <div className="iq-home-label">
                Click Start To begin 
            </div>
            <Button onClick={onStart} />
        </div>
    </div>
  )
}

export default Layout