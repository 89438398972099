import logo from "../assets/images/ionaught.png"

const Layout = ({children}) => {
  return (
    <div className='test-mate-screen'>
      <img className="image-logo" src={logo} alt="Ionaught" />
      <div className='messagebox' >
        {children}
      </div>
    </div>
  )
}

export default Layout