//importing react query tools
import { QueryClientProvider, QueryClient } from "react-query";
import { useEffect, useState } from "react";
import dotenv from "dotenv";
//importing react router dom tools
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//importing pages and components
import Dashboard from "Pages/Dashboard";
import Challenge from "Pages/Challenge";
import Leaderboard from "Pages/Leaderboard";
import Profile from "Pages/Profile";
import Signin from "Components/Signin";
import { UserContext } from "Contexts/UserContext";
import { ChallengeContext } from "Contexts/ChallengeContext";
import postData from "Services/postData";
import getData from "Services/getData";
import ScrollToTop from "Helper/ScrollToTop";
//importing styles
import "./App.css";
import ProfilePopup from "Components/ProfilePopup";
import PageNotFound from "Pages/PageNotFound";
import Sponser from "Pages/Sponser";
import TestMate from "Pages/TestMate";
import Result from "Pages/TestMate/Result";
import MarkIt from "Pages/MarkIt";
import IqTest from "Pages/IqTest";

//creating a query client
const client = new QueryClient();

dotenv.config();

function App() {
  const [userData, setUserData] = useState({
    signinVisibility: false,
    profilePopupVisibility: false,
    isLoggedin: false,
    userImg: "",
    userFullName: "",
    userId: "",
    isTryNow: false,
    rank: null,
    score: null,
    memberSince: null,
    level: null,
  });

  const [challengeSubData, setChallengeSubData] = useState({
    title: "",
    submitChallengeVisibility: false,
    submitUpdate: false,
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getData("/user").then((response) => {
        if (!response.status) {
          localStorage.removeItem("token");
          return;
        }
        setUserData({
          ...userData,
          isLoggedin: true,
          userId: response.data.userId,
          userImg: response.data.image,
          userFullName: response.data.name,
          rank: response.data.rank,
          score: response.data.score,
          memberSince: response.data.createdAt,
          level: response.data.level,
        });
        return;
      });
    }
    const url = window.location.href;
    const hasCode = url.includes("code=");
    if (hasCode) {
      const newUrl = url.split("code=");
      const code = newUrl[1];
      postData("/auth/signup", { code })
        .then((response) => {
          if (!response.status) {
            return;
          }
          setUserData({
            ...userData,
            isLoggedin: true,
            userImg: response.data.avatar_url,
            userId: response.data.userId,
            userFullName: response.data.name,
            rank: response.data.rank,
            score: response.data.score,
            memberSince: response.data.createdAt,
            level: response.data.level,
          });
          localStorage.setItem("token", response.token);
        })
        .finally(() => {
          window.history.replaceState(
            {},
            document.title,
            window.location.href.replace(`code=${code}`, "")
          );
        });
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <UserContext.Provider value={{ userData, setUserData }}>
          <ChallengeContext.Provider
            value={{ challengeSubData, setChallengeSubData }}
          >
            <QueryClientProvider client={client}>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/challenges/:title" element={<Challenge />} />
                <Route path="leaderboard" element={<Leaderboard />} />
                <Route path="/:userId" element={<Profile />} />
                <Route path="/sponsers" element={<Sponser />} />
                <Route path="/test-mate" element={<TestMate />} />
                <Route path="/test-mate-result" element={<Result />} />
                <Route path="/mark-it" element={<MarkIt />} />
                <Route path="/iq-test/*" element={<IqTest />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </QueryClientProvider>
            {userData.signinVisibility && <Signin />}
            {userData.profilePopupVisibility && <ProfilePopup />}
          </ChallengeContext.Provider>
        </UserContext.Provider>
      </div>
    </Router>
  );
}

export default App;
