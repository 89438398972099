
const InputBox = ({onChange,value}) => {
  return (
    <input
        type="text"
        onChange={
            (e) => onChange(e.target.value)
            }
        value={value}
        maxLength={2}
    />
  )
}

export default InputBox