import React from 'react'
import Button from '../Inputs/Button'
import Input from '../Inputs/Input'

const Login = ({changePage,pageNames}) => {
  const  onSubmit = (e)=>{
    e.preventDefault();
    changePage(pageNames.dashBoard)
}
  return (
    <div className="mark-it-container-home-box">
        <h1>
            Welcome Back!
        </h1>
        <form onSubmit={onSubmit}>
            <Input label="Email"/>
            <Input label="Password" type="password"/>
            <Button label="Register" type="submit"/>
        </form>
    </div>
  )
}

export default Login