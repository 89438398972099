import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import illustration from "../Assets/instruction-illustration.svg";
import ListItem from "../Components/Home/ListItem";
import postCall from "../Services/postCall";
import Layout from './Layout'

const Instructions = ({setIsExamStarted}) => {
    const navigate = useNavigate();
    useEffect(()=>{
        postCall('/user/get-test-data', {}).then(res=>{
            if(!res.status){
               return navigate('/notfound');
            }
        });
    },[]);

    
    const startExam = () => {
        postCall("/user/start-exam")
            .then(res => {
                if (!res.status) {
                    return alert(res.message);
                }
                localStorage.setItem("iq-test-token", res.token);
                setIsExamStarted(true);
                navigate("/iq-test/question/0");
            }
            );
    }

    const instructionList = [
        "This is the first online aptitude test.",
        "You will be given 30 questions.",
        "You will be given 90 minutes to complete the test.",
        "You must turn on your camera to take the test.",
        "Maximize your screen to take the test.",
        "Do not refresh the page.",
        "Do not close the browser.",
        "Do not switch tabs or windows.",
    ]
  return (
    <Layout
        title="Instructions"
        illustration= {illustration}
        onStart={startExam}
    >
        {instructionList.map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
        ))}
    </Layout>
  )
}

export default Instructions