import apiUrl from "../Config/apiUrl";

export default async (url, method, data, isFile = false) => {
  const headers = {
    authorization: localStorage.getItem("test-token")
      ? `Bearer ${localStorage.getItem("test-token")}`
      : undefined,
    "Content-Type": !isFile ? "application/json" : undefined,
  };
  let response = await fetch(`${apiUrl}${url}`, {
    method,
    headers,
    body: (isFile && data) || data ? JSON.stringify(data) : undefined,
  });
  return response.json();
};
