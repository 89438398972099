//import packages
import { useNavigate } from 'react-router-dom';

//importing assets
import home from 'assets/icons/home.svg'
import error from 'assets/icons/error.svg'

const ErrorScreen = ({ message = "Network Error" }) => {
    const navigate = useNavigate();
    return (
        <div className="popup-container">
            <div className="error-screen-popup">
                <div className="error-screen-popup-content">
                    <img src={error} alt="error" className="error-symbol" /> Oops!
                </div>
                <div style={{ fontWeight: 'bold' }}>{message}</div>
                <div className="back-to-home-btn" onClick={() => { navigate('/') }}>Back To Home <img src={home} alt="home" className="back-to-home-img" /></div>
            </div>
        </div>
    )
}

export default ErrorScreen
